<style lang="less" scoped>

  @import "../../assets/css/variables";

  .content {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .info-content {
    margin: 60px auto 0 auto;
    width: 400px;

  }

  .step-wrapper {
    margin: 0 auto 40px 40px; //多了150px在右边
  }


</style>

<template :is="view">
  <div class="content">
    <div class="info-content">
      <div class="step-wrapper">
        <el-steps :space="340" :center="true" :align-center="true" :active="1" finish-status="success">
          <el-step title="验证旧的密码"></el-step>
          <el-step title="完成"></el-step>
        </el-steps>
      </div>
      <el-form ref="form" :rules="rules" :model="pwdData" label-width="80px">
        <el-form-item label="旧密码" prop="oldPassword" class="label-3to4">
          <el-input type="password" :maxlength="30" v-model="pwdData.oldPassword" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword" class="label-3to4">
          <el-input type="password" :maxlength="30" v-model="pwdData.newPassword" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="rpPwd">
          <el-input type="password" :maxlength="30" v-model="pwdData.rpPwd" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="!formValid" class="w-full" type="primary" size="large" @click="onSubmit">保存
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>

</template>

<script>

  //webpack 会将此转为base64 然后就可以用了，src一个静态本地想对路径图片的时候会被webpack url-loader拦截
  import Vue from 'vue';
  import defaultImg from '../../assets/images/defaultImg.jpg';
  import cookie from '../../utils/http/cookie';
  import {API_ROOT}from '../../config';
  import {updatePassword} from '../../services/ucenter';
  import {mapGetters, mapActions} from "vuex";
  import {Notification} from "element-ui";
  import {valiPassword} from "../../utils/validator/element";

  export default {
    beforeRouteEnter(to, from, next) {
      next();
    },
    data() {
      const valiRpPwd = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.pwdData.newPassword) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        formValid: false,
        pwdData: {
          oldPassword: '',
          newPassword: '',
          rpPwd: ''
        },
        rules: {
          oldPassword: [
            {validator: valiPassword, trigger: 'blur'}
          ],
          newPassword: [
            {validator: valiPassword, trigger: 'blur'}
          ],
          rpPwd: [
            {validator: valiRpPwd, trigger: 'blur'}
          ]
        }

      }
    },

    computed: {
      ...mapGetters(
        {user: 'userInfo'}
      )
    },
    methods: {
      /**
       * [修改密码]
       * @return {[type]} [description]
       */
      async updatePassword() {
        const pwdData = await updatePassword(this.pwdData);
        if (pwdData && pwdData.errorCode === 0
          && pwdData.data === 'done') {
          Notification.success({
            title: '成功',
            message: '密码修改成功，请重新登录'
          });
          cookie('userInfo', false);
          this.$store.dispatch('refreshUserInfo', {});
          window.router.push('/login')
        } else {
          Notification.error({
            title: '失败',
            message: '密码修改失败:' + pwdData.errorMessage
          });
        }
        this.resetStatus();
      },
      /**
       * [重置所有的状态]
       * @return {[type]} [description]
       */
      resetStatus(){
        this.$refs.form.resetFields();
        this.formValid = false;
      },
      /**
       * 提交表单
       */
      onSubmit(){
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.updatePassword();
          }
        });
      },

    },
    watch: {

      pwdData: {
        handler: function (newVal) {
          if (newVal.newPassword && newVal.oldPassword && newVal.rpPwd) {
            this.$refs.form.validate((valid) => {
              this.formValid = valid;
            })
          }
        },
        deep: true
      },
    }
  }

</script>
